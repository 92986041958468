import { AuthContext } from '../../context';
import { Portal } from '../portal/Portal';
import { PortalConexion } from './PortalConexion';
import { useContext } from 'react';

export const Inicio = () => {

    const { conectado } = useContext( AuthContext );
    return (
        conectado
        ?
        <Portal/>
        :
        <PortalConexion/>
    )
        
}