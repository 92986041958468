import { useState, useEffect, useRef, useContext } from 'react';
import Cookies from 'js-cookie';
import { AuthContext } from '../context';
import { useNavigate  } from 'react-router-dom'

const dataServicio = ( { tipoParams, params } ) => {

    let data = {};
    switch ( tipoParams ) {
        case "json":
            data = JSON.stringify ( params );
            break;
        case "qstring":
            data = encodeURI ( Object.keys(params).map ( (key) => (key + "=" + params[key])).join('&') );
            break;
        case "formdata":
            data = new FormData();
            for (const key in params) {
                data.append(key, params[key]);
            }
            break;
        default:
            data = {};
            break;
    }
    return data;
}

export const useFetch = ( servicio, p, origen = '', bypass = false ) => {

    const { usuario, ambiente, logout } = useContext( AuthContext );
    const estaMontado = useRef (true);
    const [ estadoServicio, setEstadoServicio ] = useState ({ cargando: true, data: [], error: null });
    // const estadoServicioError = { cargando: false, data: [], error: 'Error.' }
    const history = useNavigate();

    if ( servicio.usaUsuario ) servicio.params.usua_id = usuario.usua_id;
    
    const data = dataServicio (servicio);
    const headers = new Headers();

    if ( servicio.usaHeaders ) {
        headers.append ( 'content-type', servicio.contentType);
        if ( servicio.usaToken ) {
            const jwt = Cookies.get ("jwt");
            if (!jwt) {
// console.log('_____!jwt_____', origen)            
                // return estadoServicioError;
            }
            headers.append ( 'Authorization', `Bearer ${jwt}`);
        } 
    }
     
    let headerServ = {};
    let urlServ = ambiente;

    if (servicio.usaBody) {
        headerServ = ( servicio.usaHeaders ) ? { method: servicio.metodo, body: data, headers} : { method: servicio.metodo, body: data};
        urlServ = `${urlServ}${servicio.endPoint}`;
    } else {
        headerServ = ( servicio.usaHeaders ) ? { method: servicio.metodo, headers } : { method: servicio.metodo };
        urlServ = servicio.esApi ? `${urlServ}${servicio.endPoint}?${data}` : `${urlServ}${servicio.endPoint}&${data}`;
    }

    useEffect ( () => {
        return () => {
            estaMontado.current = false;
        }
    }, []);

    useEffect( () => {

        setEstadoServicio ( { cargando: true , data: [], error: null } );
        
        try {
// console.log('__usefetch__', origen, urlServ);
// console.log('____usefetch___p_ url__headers__', origen, p, urlServ);
            fetch ( urlServ, headerServ )

            .then ( async resp => {
                if (resp.ok) 
                    return await resp.json();
                else {
                    const text = await resp.text();
                    throw new Error(text);
                }
            })

            .then ( data => {
                if (estaMontado) {
                    setEstadoServicio ({
                        cargado: false,
                        data: data,
                        error: null
                    })
                }

            })

            .catch ( (error) => {
                setEstadoServicio ({
                    cargado: true,
                    data: [],
                    error: `${JSON.parse(error.message)}`
                });
                const msje = JSON.parse(error.message);

                if (msje.code === 20) {
                    logout();
                    history('/conexion');
                }
            });
            
        } catch (error) {
            setEstadoServicio ({
                cargado: true,
                data: [],
                error: `${JSON.parse(error.message)}`
            });
            return estadoServicio;
        }

    }, [p]);
 
    if (bypass) return { cargando: false, data: [], error: null };

    return estadoServicio;
}