import { createContext } from 'react';
import { IAplicacionesPermisos, ICGestion, ITablas, ITablasRel, ITipoContratos, ITCTransiciones, ITCTransicionesPerfiles } from '../interfaces/iEndpoints';

interface ContextProps {
    tCentrosGestion:ICGestion[];
    tAyuda: ITablasRel[];
    tMonedas: ITablas[];
    tTContrato: ITipoContratos[];
    tTipoPagoCuotas: ITablas[];
    tEstadoCivil: ITablas[];
    tGenero: ITablas[];
    tBancos: ITablas[];
    tTipoCuenta: ITablas[];
    tPaises: ITablas[];
    tRegiones: ITablas[];
    tComunas: ITablas[];
    tAplicacionesPermisos: IAplicacionesPermisos[];
    tTCTransiciones: ITCTransiciones[];
    tTipoAdjunto: ITablas[];
    tTCTransicionesPerfiles: ITCTransicionesPerfiles[];
    tParametros: ITablas[];
}

export const TablasContext = createContext({} as ContextProps );
