import { FC, useContext } from 'react';
import { useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import MisPendientesRspta from './MisPendientesRspta'
import { servContratosConsultar } from '../../utils/servicios';
import { IAplicacionesPermisos, IUseFetch } from '../../interfaces/iEndpoints';
import { IconRefrescar } from '../../utils/Icons';
import {
    Box,
    Button,
    Container,
    HStack,
    Heading,
    Stack,
    VStack,
    useColorModeValue,
    Text,
  } from '@chakra-ui/react'
import { K, KContratos, KEstado } from '../../utils/constantes';
import { AuthContext } from '../../context';
import { Flex } from '@chakra-ui/react';

interface Props {
  tAplicacionesPermisos: IAplicacionesPermisos[],
}

const MisPendientes: FC<Props> = ({ tAplicacionesPermisos }) => {

    const { usuario } = useContext( AuthContext );
  
    const [ consultar, setConsultar ] = useState (1);

    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      cges_id_list: '',
      esta_id_list: KEstado._FIRMA_1_OK,
      pnat_rut : usuario ? usuario.pnat_rut : '?',
      ctto_nombre: '',
      ctto_fecha_ini: '',
      ctto_fecha_fin: '',    
    }    

    const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
    const bgColor = useColorModeValue("white", "gray.700");
    const bgColorC  = useColorModeValue("gray.200", "gray.900");

    const onClickConsultar = (e: React.SyntheticEvent) => {
      setConsultar(consultar+1);
      servContratosConsultar.params = {
        ...servContratosConsultar.params,
        random: Math.random(),
      }    
    }

    const contratosServ:IUseFetch = useFetch (servContratosConsultar, JSON.stringify ( servContratosConsultar.params ), 'MisPendientes');

    return (

      <Container 
        py={{ base: '1', md: '1' }} 
        px={{ base: '1', md: '1' }} 
        maxW='6xl' 
        p="3" 
        bg={bgColorC}
      >

      <Flex
        direction={{ base: 'column', lg: 'row' }}
        overflowY="auto"
        bg={ bgColor }
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
        borderRadius={{ base: 'none', sm: 'md' }}
      >
        <Stack direction={'column'} spacing={5}>

            <Box overflowX="auto" bg={bgColor} p={5} >
              <VStack direction={{ base: 'column', md: 'row' }} spacing={5}>

                <HStack spacing={3}>
                  <Heading size='md' color={colorTitulo}>{KContratos._MP_TITULO1}</Heading>
                  <Text fontSize='md'>{KContratos._MP_TITULO2}</Text>
                  <Button onClick={onClickConsultar} colorScheme='teal' variant='ghost' leftIcon={<IconRefrescar/>} />
                </HStack>

                <MisPendientesRspta respuesta={contratosServ} />

              </VStack>              
            </Box>
        </Stack>              
        </Flex>              
      </Container>
    )
  }

  export default MisPendientes;