import {
    Box,
    Stack,
  } from '@chakra-ui/react'
import RoutesLogin from '../../routers/RoutesLogin';
   
export const PortalConexion = () => {

    return (
      <Box flex="0" p={0}>
        <Stack spacing={{ base: '0', lg: '0' }} >
          <RoutesLogin />
        </Stack>
      </Box>
    )
  }

